import {
    AdminRoutes,
    AuthedRoutes,
    AnonymousRoutes,
    Login,
    Logout,
    AdminDashboard,
    ObjectList,
    ManageObject,
    LoadLastURL,
    CurrentNotOffered,
    PrivacyPolicy,
    SignUp,
    Home,
    Insights,
    GoogleLogin,
    AccountsAndProperties,
    MakeBetterOffer,
    RunAd,
    InvestigateConversionIssue,
    InvestigateTechnicalIssue,
    Ghost,
    ConversionHome
} from 'pages';

const routes = [
    {
        path: 'logout',
        element: <Logout />,
    },
    {
        path: 'login',
        element: <Home />,
    },

    {
        path: 'load_last_url',
        element: <LoadLastURL />,
    },
    {
        path: '',
        element: <AnonymousRoutes />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: 'conversion_rate',
                element: <ConversionHome />,
            },
            {
                path: 'privacy_policy',
                element: <PrivacyPolicy />,
            },
            {
                path: 'google_login',
                element: <GoogleLogin />,
            },
        ],
    },
    {
        path: '',
        element: <AuthedRoutes />,
        children: [
            {
                path: 'insights',
                element: <Insights />,
            },
            {
                path: 'accounts_and_properties',
                element: <AccountsAndProperties />,
            },
            {
                path: 'make_better_offer',
                element: <MakeBetterOffer />,
            },
            {
                path: 'run_ad',
                element: <RunAd />,
            },
            {
                path: 'investigate_conversion_issue',
                element: <InvestigateConversionIssue />,
            },
            {
                path: 'investigate_technical_issue',
                element: <InvestigateTechnicalIssue />,
            },
        ],
    },
    {
        path: 'admin',
        element: <AdminRoutes />,
        children: [
            {
                path: 'ghost_as_user',
                element: <Ghost />,
            },
        ],
    },
];

export default routes;
